<template>
  <base-card class="return-action">
    <base-text
      v-if="name"
      class="return-action__eyebrow"
      type="caption"
    >
      <render-content :data="contentData">
        {{ $content.moduleReturnAction.eyebrow }}
      </render-content>
    </base-text>
    <base-text
      v-if="heading && !isScheduling && !showReturnsTracking"
      class="return-action__heading"
      tag="h2"
      type="display-large"
    >
      <render-content :data="contentData">
        {{ heading }}
      </render-content>
      <base-loader
        v-if="loader"
        class="return-action__loader"
      />
    </base-text>

    <base-text
      v-if="multiLabelAllowed"
      class="return-action__subheading"
      type="display-small"
    >
      {{ contentModule.subheading }}
    </base-text>

    <span
      v-if="isScheduling"
      class="return-action__pickup-scheduling"
    >
      <transition name="fadein">
        <base-loader
          v-if="isScheduling"
          size="small"
          class="return-action__pickup-scheduling__loader"
        />
      </transition>
      <base-text>
        {{ $content.moduleVehoHomePickup.pickupScheduling }}
      </base-text>
    </span>
    <span v-if="!isScheduling && !showReturnsTracking">
      <base-text
        v-if="hasCopy"
        class="return-action__copy"
      >
        <render-content
          :key="rerenderKey"
          :data="contentData"
        >
          {{ copy }}
        </render-content>
      </base-text>
      <markup-renderer
        v-if="body"
        class="return-action__body"
        :content="body"
        :customization-data="{ pickupDate }"
      />
    </span>

    <!-- Return Address -->
    <base-text
      v-if="contentType === 'default' && showReturnAddress && !multiLabelAllowed"
      data-testid="return_address"
      class="return-action__address"
    >
      <address-display
        :address="returnAddress"
      />
    </base-text>

    <!-- Label section -->
    <component
      :is="labelComponent"
      v-if="(labelComponent && ! multiLabelAllowed) || labelType === 'labelError'"
      v-track="'malomo tracking page clicked'"
      class="return-action__label"
      :return-id="returnId"
      :return-key="returnKey"
      :order-name="name"
      :address="address"
      :labels-enabled="labelsEnabled"
      :locations="filteredLocations"
      :return-method="returnMethod"
      :tracking-url="trackingUrl"
      :expires-at="contentData.returnBy"
      :qr-code-url="qrCodeUrl"
      :packing-slips-enabled="packingSlipsEnabled"
      :has-combined-labels-with-packing-slips-flag="hasCombinedLabelsWithPackingSlipsFlag"
      v-bind="label"
      @label="(val) => $emit('label', val)"
      @generate="$emit('generate')"
      @toggle-view="toggleView"
      @update="(val) => $emit('update', val)"
      @show-qr="(val) => showQr(val)"
    >
      <template
        v-if="labelAddressErrors"
        #errorDetails
      >
        <base-paragraph>{{ contentModulePageReturnStatusAddressValidationMessage }}</base-paragraph>
      </template>
      <template
        v-if="labelAddressErrors"
        #fixAction
      >
        <base-button
          type="primary"
          icon="warning"
          data-testid="update_address"
          @click.prevent="$emit('toggleEditCustomerInfo')"
        >
          {{ contentModulePageReturnStatusAddressValidationUpdate }}
        </base-button>
      </template>
      <template
        v-if="packingSlipsEnabled && ! isLabelWithPackingSlip"
        #packingSlip="{ type, classes }"
      >
        <print-packing-slip
          data-testid="packing-slip-paired"
          :type="type"
          :class="classes ?? '' + ' return-action__packing-slip'"
          :return-key="returnKey"
          @packing-slip-alert="packingSlipAlert"
        />
      </template>
    </component>

    <div
      v-if="(multiLabelAllowed && labelType !== 'labelError')"
    >
      <printed-labels-modal
        v-if="modalName === 'printed-label'"
        :show="modalName === 'printed-label'"
        :labels="returnLabels"
        :return-id="returnId"
        :shop-id="shopId"
        :return-key="returnKey"
        :order-name="name"
        :return-by="returnBy"
        :packing-slips-enabled="packingSlipsEnabled"
        :has-combined-labels-with-packing-slips-flag="hasCombinedLabelsWithPackingSlipsFlag"
        :content="contentModule"
        @close-modal="closeModal"
      />
      <qr-codes-modal
        v-if="modalName === 'qr-code'"
        :show="modalName === 'qr-code'"
        :labels="returnLabels"
        :return-id="returnId"
        :shop-id="shopId"
        :return-key="returnKey"
        :order-name="name"
        :return-by="returnBy"
        :content="contentModule"
        @close-modal="closeModal"
      />
      <usps-qr-generation-window-alert
        v-if="showUspsQrSelector && multiLabelReady && labelWasCreatedToday"
        class="return-action__multi-label-alert"
      />
      <alert-banner 
        v-if="!multiLabelReady"
        class="return-action__multi-label-alert"
        type="info"
        :heading="contentModule.labelsLoadingHeading"
        :description="contentModule.labelsLoadingCopy"
        inline
        hasIcon
      />
      <base-button
        type="secondary"
        class="return-action__modal-button"
        :disabled="!multiLabelReady"
        :activated="!multiLabelReady"
        @click="showModal('qr-code')"
      >
        <base-icon
          class="return-action__icon"
          name="qr-code"
        />
        <div>
          <base-text type="heading">
            {{ contentModule.qrButtonHeading }}
          </base-text>

          <base-text type="body-2">
            {{ contentModule.qrButtonDescription }}
          </base-text>
        </div>
      </base-button>

      <base-button
        type="secondary"
        class="return-action__modal-button"
        :disabled="!multiLabelReady"
        :activated="!multiLabelReady"
        @click="showModal('printed-label')"
      >
        <base-icon
          class="return-action__icon"
          name="printer"
        />
        <div>
          <base-text type="heading">
            {{ contentModule.printedLabelButtonHeading }}
          </base-text>

          <base-text type="body-2">
            {{ contentModule.printedLabelButtonDescription }}
          </base-text>
        </div>
      </base-button>

      <template
        v-if="(!!nextContent && nextContent.length > 0) && !showReturnsTracking && multiLabelReady"
      >
        <base-text
          tag="h2"
          type="display-medium"
        >
          {{ nextHeading }}
        </base-text>
        <div class="return-action__next-container">
          <div
            v-for="(item, i) in nextContent"
            :key="i"
            class="return-action__next-row"
          >
            <div class="return-action__next-text">
              <base-text
                class="return-action__next-heading"
                type="body"
              >
                <render-content :data="contentData">
                  {{ item.subheading }}
                </render-content>
              </base-text>
              <base-text
                class="return-action__next-body"
                type="body-2"
              >
                <render-content :data="contentData">
                  {{ item.body }}
                </render-content>
              </base-text>
            </div>
          </div>
        </div>
      </template>
    </div>

    <print-packing-slip
      v-if="!labelComponent && packingSlipsEnabled"
      class="return-action__packing-slip"
      data-testid="packing-slip-single"
      :return-key="returnKey"
      @packing-slip-alert="packingSlipAlert"
    />

    <alert-banner
      v-if="packingSlipAlertData"
      class="return-action__packing-slip-error-banner"
      type="error"
      :heading="packingSlipBannerHeading"
      :description="packingSlipAlertData"
      :inline="true"
    />

    <div v-if="!multiLabelAllowed">
      <div
        v-if="addendum && !showReturnsTracking"
        class="return-action__addendum-wrapper"
      >
        <img
          class="return-action__addendum-email-icon"
          src="/img/icons/mail.svg"
          alt="email icon"
        />
        <base-text
          class="return-action__addendum"
          type="body-2"
        >
          <render-content :data="contentData">
            {{ addendum }}
          </render-content>
        </base-text>
      </div>
      <div
        v-if="isMobile && mobileAddendum"
        class="return-action__addendum-wrapper"
      >
        <img
          class="return-action__addendum-email-icon"
          src="/img/icons/screencapture.svg"
          alt="screen capture icon"
        />
        <base-text
          class="return-action__addendum"
          type="body-2"
        >
          <render-content>
            {{ mobileAddendum }}
          </render-content>
        </base-text>
      </div>

      <template v-if="showInstructions && !showReturnsTracking">
        <hr class="return-action__divider" />
        <base-text
          class="return-action__shipping-heading"
          tag="h2"
          type="display-small"
        >
          <render-content :data="contentData">
            {{ $content.moduleReturnAction.instructionsHeading }}
          </render-content>
        </base-text>
        <div
          v-if="showShippingTimeMessage"
          class="return-action__message"
        >
          <base-icon
            class="return-action__message-icon"
            name="info"
          />
          <base-text
            class="return-action__message-copy"
            type="body-2"
          >
            <render-content :data="contentData">
              {{ $content.moduleReturnAction.timeFrameCopy }}
            </render-content>
          </base-text>
        </div>
        <ol class="return-action__instructions">
          <base-text
            v-for="(instruction, index) in instructions"
            :key="index"
            class="return-action__instruction"
            tag="li"
          >
            {{ instruction }}
          </base-text>
        </ol>
      </template>

      <template
        v-if="(!!nextContent && nextContent.length > 0) && !showReturnsTracking"
      >
        <hr class="return-action__divider" />
        <base-text
          tag="h2"
          type="display-medium"
        >
          {{ nextHeading }}
        </base-text>
        <div class="return-action__next-container">
          <div
            v-for="(item, i) in nextContent"
            :key="i"
            class="return-action__next-row"
          >
            <div class="return-action__next-icon-container">
              <base-icon
                class="return-action__next-icon"
                :name="item.icon"
                view-box="0 0 20 20"
                aria-hidden="true"
              />
            </div>
            <div class="return-action__next-text">
              <base-text
                class="return-action__next-heading"
                type="body"
              >
                {{ item.subheading }}
              </base-text>
              <base-text
                class="return-action__next-body"
                type="body-2"
              >
                {{ item.body }}
              </base-text>
            </div>
          </div>
        </div>
      </template>
    </div>
  </base-card>
</template>

<script>
import {
  AlertBanner,
  BaseCard,
  BaseText,
  BaseIcon,
  BaseButton,
  BaseModal,
} from '@loophq/design-system';
import BaseLoader from '@/components/feedback/BaseLoader';
import MarkupRenderer from '@/components/renderers/MarkupRenderer';
import LabelError from './LabelError';
import LabelProcessing from './LabelProcessing';
import PrintLabel from './PrintLabel';
import PrepaidLabels from './PrepaidLabels';
import ReturnCode from './ReturnCode';
import ShopifyPosDropOff from './ShopifyPosDropOff';
import LoopPosDropOff from './LoopPosDropOff';
import HappyReturnsDropOff from './HappyReturnsDropOff';
import OliveHomePickup from './OliveHomePickup';
import UspsQrSelector from './UspsQrSelector';
import AddressDisplay from '@/components/AddressDisplay';
import { carriers } from '@/js/constants/carriers';
import { featureFlags } from '@/js/constants/featureFlags';
import QrCode from '@/js/controllers/qrCode';
import formatDate from '@/js/helpers/formatDate';
import { formatCurrency } from '@/js/helpers/formatCurrency';
import { getCloseLocations } from '@/js/helpers/locations';
import {
  outcomes,
  labels,
  labelComponents,
  views,
  states
} from './actionMaps';
import firstMile from '@/js/constants/firstMile';
import useStorageMethods from '@/js/mixins/store';
import PrintPackingSlip from './PrintPackingSlip.vue';
import QrCodesModal from './LabelListModal/QrCodesModal.vue';
import PrintedLabelsModal from './LabelListModal/PrintedLabelsModal.vue';
import UspsQrGenerationWindowAlert from '@/views/StatusPage/UspsQrGenerationWindowAlert.vue';
import RenderContent from '@/components/renderers/RenderContent.vue';

const { setStore, getStore } = useStorageMethods();

export default {
  components: {
    RenderContent,
    UspsQrGenerationWindowAlert,
    AlertBanner,
    BaseButton,
    BaseCard,
    BaseText,
    BaseIcon,
    BaseLoader,
    BaseModal,
    LabelError,
    LabelProcessing,
    PrintLabel,
    PrepaidLabels,
    PrintedLabelsModal,
    QrCodesModal,
    ReturnCode,
    AddressDisplay,
    MarkupRenderer,
    HappyReturnsDropOff,
    ShopifyPosDropOff,
    LoopPosDropOff,
    OliveHomePickup,
    UspsQrSelector,
    PrintPackingSlip,
  },
  props: {
    name: {
      type: String,
      required: true
    },
    returnId: {
      type: [String, Number],
      required: true
    },
    returnKey: {
      type: String,
      required: true,
    },
    outcome: {
      type: String,
      required: true,
      default: 'default',
      validator: (val) => ['donate', 'review', 'keep', 'default'].includes(val)
    },
    label: {
      type: Object,
      required: false,
      default: null
    },
    multiLabelCount: {
      type: Number,
      required: false,
      default: 0
    },
    customer: {
      type: Object,
      required: false,
      default: null
    },
    address: {
      type: Object,
      required: false,
      default: null
    },
    instructions: {
      type: Array,
      required: false,
      default: () => []
    },
    returnAddress: {
      type: Object,
      required: false
    },
    returnBy: {
      type: String,
      required: false
    },
    keepItems: {
      type: Boolean,
      required: false
    },
    labelsEnabled: {
      type: Boolean,
      required: false
    },
    instantExchangeReturnBy: {
      type: String,
      required: false
    },
    instantExchangeReceipt: {
      type: Object,
      required: false,
      validator: (val) => 'status' in val && 'amount' in val
    },
    locations: {
      type: Array,
      required: false
    },
    policy: {
      type: Object,
      required: false
    },
    returnMethod: {
      type: Object,
      required: false,
    },
    shopId: {
      type: [String, Number],
      required: true
    },
    trackingUrl: {
      type: String,
      required: false,
    },
    qrCodeUrl: {
      type: String,
      required: false,
    },
    isFullKeep: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSchedulingVehoPickup: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelIsProcessing: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: String,
      required: false,
      default: '',
    },
    toReturn: {
      type: Array,
      required: true
    },
    labelAddressErrors: {
      type: Object,
      required: false,
      default: null,
    },
    showReturnsTracking: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  emits: [
    'toggleQrAlert',
    'createLabelQr',
    'label',
    'generate',
    'update',
    'toggleEditCustomerInfo'
  ],
  data: () => {
    return {
      bypass: {
        [views.QR_CODE]: false,
      },
      showUspsQrSelector: true,
      loader: false,
      rerenderKey: 0,
      packingSlipAlertData: null,
      displayQrLabelsModal: false,
      displayPrintedLabelsModal: false,
      modalName: null,
    };
  },
  computed: {
    events() {
      return {
        packingSlipAlert: this.packingSlipAlert,
      };
    },
    returnLabels() {
      return this.$store.getters['firstMile/labels'] ?? [];
    },
    labelType() {
      const { label } = this.label;

      if (this.labelIsProcessing) {
        return 'labelProcessing';
      }

      if (this.keepItems) {
        return null;
      }

      if (this.state === 'expired' && this.hideLabelButtonSettingIsOn) {
        return null;
      }

      if (this.returnMethod?.name === firstMile.VEHO) {
        // Check for any label or schedule failures
        if (this.vehoFailed) {
          return 'vehoError';
        }
        return firstMile.VEHO;
      }

      if (this.label?.error || label?.status === 'failed') {
        return 'labelError';
      }

      if (label?.status === 'queued') {
        return 'queued';
      }

      // Display QR code
      if ((this.qrCodeUrl || label?.qrCodeUrl) && !this.bypass[views.QR_CODE]) {
        // USPS and Fedex have differences in content customizations
        // The ReturnCode view will be shown in either case 
        if (this.carrierIsUSPS && this.uspsQrSettingIsOn) return 'uspsCode';
        if (this.carrierIsUSPS && this.integrationIsSblww) return 'uspsCode';
        if (this.carrierIsDHLSeko) return 'uspsCode';
        if (this.carrierIsNewgistics) return 'uspsCode';
        if (this.carrierIsInpost) return 'inpostCode';
        if (this.carrierIsAsda) return 'asdaCode';
        if (this.carrierIsEvri) {
          return 'evriCode';
        }
        if (this.carrierIsCanadaPost) return 'canadaPostCode';
        if (this.carrierIsRoyalMail) return 'royalMailCode';
        // Fedex
        return 'code';
      }

      if (this.outcome === 'donate') {
        return (this.packingSlipsEnabled && this.hasCombinedLabelsWithPackingSlipsFlag) ? 'labelWithPackingSlip' : 'label';
      }

      if (this.returnMethod?.name === firstMile.VEHO) {
        return firstMile.VEHO;
      }

      const prepaid = label?.shippingProviderId === 2;
      if (this.label?.generate || (label && !prepaid)) {
        this.determineUspsQrSelector();
        if (this.showUspsQrSelector) return 'uspsQrSelector';
        return (this.packingSlipsEnabled && this.hasCombinedLabelsWithPackingSlipsFlag) ? 'labelWithPackingSlip' : 'label';
      }

      if (this.label?.prepaid || prepaid) {
        return 'prepaid';
      }

      if (this.returnMethod?.name === firstMile.HAPPY_RETURNS && this.showReturnMethodInstructions()) {
        return 'happyReturns';
      }

      if (this.returnMethod?.name === firstMile.SHOPIFY_POS) {
        return 'shopifyPos';
      }

      if (this.returnMethod?.name === firstMile.LOOP_POS) {
        return 'loopPos';
      }

      if (this.returnMethod?.name === firstMile.OLIVE) {
        return 'olive';
      }

      return null;
    },
    labelComponent() {
      return (this.labelType === 'labelWithPackingSlip')
        ? labelComponents.get('label')
        : labelComponents.get(this.labelType);
    },
    contentType() {
      if (states.get(this.state)) {
        return 'custom';
      }

      if (outcomes.get(this.outcome)) {
        return 'custom';
      }
      if (this.isFullKeep) {
        return 'custom';
      }
      if (labels.get(this.labelType)) {
        return 'label';
      }
      return 'default';
    },
    contentModule() {
      if (states.get(this.state)) {
        return this.$content[states.get(this.state)];
      }

      if (outcomes.get(this.outcome)) {
        return this.$content[outcomes.get(this.outcome)];
      }

      if (this.isFullKeep) {
        return this.$content[outcomes.get('keep')];
      }

      if (this.labelType === 'labelError') {
        return this.$content[labels.get(this.labelType)];
      }
      
      if (this.multiLabelCount > 1) {
        return this.$content['moduleReturnCodeForMultiLabel'];
      }
      
      if (labels.get(this.labelType)) {
        if (this.multiLabelAllowed) {
          if (labels.get(this.labelType) === 'moduleUspsReturnCode') {
            return this.$content['moduleReturnCodeForMultiLabel'];
          } else if (labels.get(this.labelType) === 'moduleUspsQrSelector') {
            return this.$content['moduleUspsQrSelectorForMultiLabel'];
          }
        }
        return this.$content[labels.get(this.labelType)];
      }

      return this.$content.moduleReturnAddress;
    },
    heading() {
      return this.contentModule?.heading;
    },
    copy() {
      return this.contentModule?.copy;
    },
    body() {
      return this.contentModule?.body;
    },
    pickupDate() {
      const dateParts = this.returnMethod?.meta?.veho?.serviceDate.split('-');
      if (!dateParts) {
        return '';
      }
      const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
      return date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    },
    addendum() {
      return this.contentModule?.addendum;
    },
    mobileAddendum() {
      return this.contentModule?.mobileAddendum;
    },
    contentData() {
      return {
        returnName: this.name,
        carrier: this.carrier,
        returnBy: formatDate(this.returnBy),
        chargeDate: formatDate(this.instantExchangeReturnBy),
        chargeAmount: formatCurrency(this.instantExchangeReceipt?.amount?.value, this.$settings.currency),
        customerEmail: this.customer?.email,
        instantType: this.hasInstantReturn ? 'return' : 'exchange',
      };
    },
    carrier() {
      const carrier = this.$store.getters['firstMile/carrier']?.toLowerCase();
      if (carrier?.startsWith('usps')) return 'USPS';
      if (carrier?.startsWith('fedex')) return 'FedEx';
      if (carrier?.startsWith('upsmail')) return 'UPS Mail Innovations';
      if (carrier?.startsWith('ups')) return 'UPS';
      if (carrier?.startsWith('dhl')) return 'DHL';
      if (carrier?.startsWith('evri')) return 'Evri ParcelShop';
      if (carrier?.startsWith('canada')) return 'CanadaPost';
      if (carrier?.startsWith('royal')) return 'Royal Mail';
      if (carrier?.startsWith('newgistics')) return 'USPS';
      if (carrier?.startsWith('global-e')) return 'Global-e';

      return this.$store.getters['firstMile/carrier'] || 'carrier';
    },
    hasCopy() {
      if (this.labelComponent === 'PrintLabel' && !this.contentData.carrier) {
        return false;
      }

      return this.copy;
    },
    showInstructions() {
      if (this.isFullKeep) {
        return false;
      }

      const blockedTypes = [
        'generate',
        'labelError',
        'queued',
        'happyReturns',
        'shopifyPos',
        'loopPos',
        'uspsQrSelector',
        'uspsCode',
        firstMile.VEHO,
        'vehoError',
        'inpostCode',
        'asdaCode',
        'evriCode',
      ];
      const allowedOutcomes = ['donate', 'default'];
      // If the USPS QR Selector is being shown, don't show the instructions
      return !blockedTypes.includes(this.labelType) && allowedOutcomes.includes(this.outcome) && this.instructions.length > 0;
    },
    showReturnAddress() {
      return this.returnAddress && !this.$settings.hideAddressOnConfirmation;
    },
    showShippingTimeMessage() {
      // This message is to remind the user to ship their item back
      // Once the status is not 'authorized', it's no longer relevant
      return this.instantExchangeReceipt?.status === 'authorized' && this.keepItems === false;
    },
    filteredLocations() {
      return getCloseLocations(this.locations);
    },
    uspsQrSettingIsOn() {
      return this.$settings.generateUspsQrCodes;
    },
    isMobile() {
      return this.$screen.width <= 680;
    },
    hasInstantReturn() {
      return this.$store.getters.hasFeature(featureFlags.INSTANT_REFUNDS);
    },
    multiLabelAllowed() {
      return this.$store.getters.hasFeature(featureFlags.MULTI_LABELS_ALLOWED) && this.multiLabelCount > 1;
    },
    multiLabelReady() {
      return this.returnLabels.length === this.multiLabelCount;
    },
    integrationIsSblww() {
      return this.label?.label.labelUrl?.includes('easyship');
    },
    carrierIsUSPS() {
      return this.label?.label?.carrier === carriers.USPS || this.label?.label?.carrier === carriers.USPSRETURNS;
    },
    carrierIsDHLSeko() {
      return this.label?.label?.carrier === carriers.DHLSEKO;
    },
    carrierIsNewgistics() {
      return this.label?.label?.carrier.toLowerCase() === carriers.NEWGISTICS;
    },
    carrierIsInpost() {
      return this.label?.label?.carrier === carriers.INPOST;
    },
    carrierIsAsda() {
      return this.label?.label?.carrier === carriers.ASDA;
    },
    carrierIsEvri() {
      return this.label?.label?.carrier === carriers.EVRI || (this.integrationIsSblww && this.label?.label.carrier.includes('Evri'));
    },
    carrierIsCanadaPost() {
      return this.label?.label?.carrier === carriers.CANADA_POST || (this.integrationIsSblww && this.label?.label.carrier.includes('Canada Post'));
    },
    carrierIsRoyalMail() {
      return this.label?.label?.carrier === carriers.ROYAL_MAIL;
    },
    // USPS QR code generation requires the label date to be today, and not in the past
    labelWasCreatedToday() {
      const labelDate = new Date(this.label?.label?.createdAt ? `${this.label.label.createdAt}Z` : null);
      const today = new Date();

      return labelDate.toLocaleDateString('en', { timeZone: 'America/New_York' }) === today.toLocaleDateString('en', { timeZone: 'America/New_York' });
    },
    vehoFailed() {
      return this.label?.error || this.label?.label?.status === 'failed' || !this.returnMethod?.scheduledAt;
    },
    nextHeading() {
      if (this.labelComponent === 'ReturnCode' || this.returnMethod?.name === firstMile.VEHO) {
        return this.contentModule.nextHeading;
      } else return '';
    },
    nextContent() {
      const subheadings = this.contentModule?.nextSubheadings ?? [];
      const bodies = this.contentModule?.nextBodies ?? [];
      let icons = [];
      if (this.labelComponent === 'ReturnCode') {
        icons = firstMile.RETURN_CODE_NEXT_ICONS;
      } else if (this.returnMethod?.name === firstMile.VEHO && !this.vehoFailed) {
        icons = firstMile.VEHO_NEXT_ICONS;
      }

      const nextContent= [];
      let i = 0;
      while (subheadings[i] && bodies[i]) {
        nextContent.push({
          subheading: subheadings[i],
          body: bodies[i],
          icon: icons[i] ?? 'package-alt'
        });
        i += 1;
      }

      return nextContent;
    },
    isScheduling() {
      if (this.$store.state.firstMile.returnMethodState === firstMile.VEHO_STATE_TIMED_OUT) {
        return false;
      }
      return this.isSchedulingVehoPickup;
    },
    hideLabelButtonSettingIsOn() {
      return this.$settings.hideLabelButtonForExpiredReturns;
    },
    packingSlipsEnabled() {
      if (!this.toReturn?.length) {
        return false;
      }
      return this.policy?.packingSlipsEnabled;
    },
    contentModulePageReturnStatusAddressValidationMessage() {
      return this.$store.getters['content'].pageReturnStatus.addressValidationMessage;
    },
    contentModulePageReturnStatusAddressValidationUpdate() {
      return this.$store.getters['content'].pageReturnStatus.addressValidationUpdate;
    },
    packingSlipBannerHeading() {
      return this.$content.modulePackingInstructions.packingSlipPopupErrorHeading;
    },
    isLabelWithPackingSlip() {
      return (this.labelType === 'label' || this.labelType === 'labelWithPackingSlip')
        && this.packingSlipsEnabled
        && this.hasCombinedLabelsWithPackingSlipsFlag;
    },
    hasCombinedLabelsWithPackingSlipsFlag() {
      return !!(this.$store.getters.hasFeature(featureFlags.COMBINE_LABELS_WITH_PACKING_SLIPS) && this.label?.label?.labelPackingSlip);
    },
  },
  watch: {
    carrier() {
      this.rerenderKey += 1;
    }
  },
  methods: {
    showModal(name) {
      this.modalName = name;
    },
    closeModal() {
      this.modalName = null;
    },
    determineUspsQrSelector() {
      // Check localStorage if a selection has previously been made
      this.showUspsQrSelector = this.returnMethod?.name !== firstMile.VEHO
        && this.uspsQrSettingIsOn
        && this.carrierIsUSPS
        && this.labelWasCreatedToday
        && !getStore(`madeSelection${this.returnId}`)
        && !this.integrationIsSblww;
    },
    async toggleView(view) {
      this.bypass[view] = !this.bypass[view];

      // If customer wants to view the QR code and it has not been created yet
      if (!this.bypass[view] && (!this.qrCodeUrl && !this.label.label.qrCodeUrl)) {
        // Show loader and hide alert banner
        this.loader = true;
        this.$emit('toggleQrAlert', false);

        try {
          var res = await QrCode.getLabelQrCode(this.shopId, this.returnId, this.label.label.id);
          if (res.data.qr_code === null) {
            throw new Error('QR code url is null');
          }
        } catch (error) {
          // Show alert banner and log error
          this.$emit('toggleQrAlert', true);
          console.error(error);
        }
        // eslint-disable-next-line vue/no-mutating-props
        this.qrCodeUrl = res.data.qr_code;
        this.loader = false;
      }
    },
    async showQr(customerWantsQr) {
      // Stop displaying the selector once a selection is made
      this.showUspsQrSelector = false;
      // Record customer's action on localStorage
      setStore(`madeSelection${this.returnId}`, true);
      // Customer chose to view QR code and a QR code has not been created yet
      if (customerWantsQr && ((!this.label.label.qrCodeUrl && !this.qrCodeUrl) || this.integrationIsSblww)) {
        this.$emit('createLabelQr');
      }
    },
    showReturnMethodInstructions() {
      if (this.returnMethod.name === firstMile.HAPPY_RETURNS) {
        return this.returnMethod.qrCodeUrl !== null;
      }
    },
    packingSlipAlert(alertData) {
      this.packingSlipAlertData = alertData;
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.return-action';

#{$block} {
  border: 1px solid var(--grey-200);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 10%);
  border-radius: var(--spacing-200);
  padding: var(--spacing-500);

  &__heading {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-200);
  }

  &__subheading {
    margin-top: var(--spacing-300);
    margin-bottom: var(--spacing-200);
  }

  &__loader {
    margin-left: var(--spacing-300);
  }

  &__copy {
    margin: var(--spacing-200) 0 var(--spacing-400);
  }

  &__body {
    margin: 12px var(--spacing-200) var(--spacing-400) 0;

    :deep(p) {
      line-height: inherit;
      color: var(--heading-color);
    }

    @media screen and (max-width: $break-small) {
      margin-top: var(--spacing-300);
    }
  }

  :deep(.return-action__multi-label-alert) {
    margin-top: var(--spacing-300);
    margin-bottom: var(--spacing-300);
  }

  :deep(.return-action__modal-button) {
    width: 100%;
  }

  :deep(.return-action__modal-button + .return-action__modal-button) {
    margin-top: var(--spacing-300);
  }

  :deep(.return-action__modal-button .base-button__text) {
    align-items: center;
  }

  :deep(.return-action__icon) {
    height: 4rem;
    width: 4rem;
  }

  :deep(.return-action__icon .base-icon__icon) {
    max-width: 32px;
    margin: auto;
  }

  &__label {
    margin: 0 0 var(--spacing-400) 0;
  }

  :deep(.base-button__text) {
    display: flex;

    :deep(.base-icon) {
      width: 30px;
      height: 30px;
    }
  }

  &__packing-slip {
    width: fit-content;
  }

  &__addendum {
    &-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: normal;
      align-items: center;
      gap: var(--spacing-200);
    }

    &-email-icon {
      width: 38px;
      padding: var(--spacing-200);
      border: thin solid gray;
      border-radius: 50%;
    }
  }

  &__divider {
    border: 0;
    border-top: 2px solid var(--grey-200);
    margin: var(--spacing-400) -2rem;
  }

  &__shipping-heading {
    margin-bottom: var(--spacing-400);
  }

  &__message {
    display: flex;
    border: 2px solid var(--grey-800);
    border-radius: var(--corners);
    padding: var(--spacing-200) var(--spacing-300);
    margin-bottom: var(--spacing-400);
  }

  &__message-icon {
    --icon-color: var(--grey-600);

    flex-shrink: 0;
    margin-right: var(--spacing-200);
  }

  &__instructions {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

  &__instruction {
    & + & {
      margin-top: var(--spacing-300);
    }
  }

  &__address {
    margin-bottom: var(--spacing-400);
  }

  &__next {
    &-container {
      display: flex;
      flex-direction: column;
    }

    &-row {
      display: flex;
      align-items: flex-start;
      margin-top: var(--spacing-400);
    }

    &-icon {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-container {
        border: 1px solid #c9cbcf;
        border-radius: 12px;
        margin-right: var(--spacing-300);
      }

      :deep(.base-icon__icon) {
        width: var(--spacing-300);
        height: var(--spacing-300);
      }
    }

    &-heading {
      color: #000;
      font-weight: 600;
    }

    &-body {
      font-size: 14px;
    }

    &-text {
      display: flex;
      flex-direction: column;
    }
  }

  &__pickup-scheduling {
    display: flex;
    align-items: center;
    margin: var(--spacing-300) 0 var(--spacing-400) 0;

    &__loader {
      margin-right: var(--spacing-200);
      color: var(--primary-color);
    }
  }

  &__packing-slip-error-banner {
    margin-top: var(--spacing-300);
    border: 0;
  }
}
</style>
